import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from "../../images/manual_testing-01.png"
import mobile_testing from "../../images/mobile_testing-01.png"
import saas_testing from "../../images/saas_testing-01.png"
import usability_testing from "../../images/usability_testing-01.png"
import web_testing from "../../images/web_testing-01.png"
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout
      className="services-page-container"
      data={data}
      pageTitle={"Visiomate - Quality Assurance Services"}
      descrip={
        "Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"
      }
    >
      <div
        className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Revolutionize Your App Development with No-Code Solutions</Title>
              <br />
              <p>
              Unlock the power of no-code application development to create custom apps quickly
              and cost-effectively. Our intuitive no-code platform enables anyone—regardless of
              technical expertise—to design, build, and deploy applications with ease.  <br />
                <br />
                Experience faster development cycles, reduced costs, and the flexibility to adapt
                to changing business needs. Start your journey towards streamlined app creation today!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
        <div className="service-heading-and-detail">
          <Title>No-Code App Development</Title>
        </div>
        <div className="service-cards-wrapper">
          <ServiceCard
            img_src={manual_testing}
            img_alt={"manual_testing"}
            card_heading={"Faster Development"}
            card_para={
              "No-code platforms enable users to create functional applications quickly, often within hours or days, compared to traditional development methods that can take weeks or months."
            }
          />
          <ServiceCard
            img_src={usability_testing}
            img_alt={"usability_testing"}
            card_heading={"Cost-Effective"}
            card_para={
              "Building applications with no-code platforms can reduce development costs by up to 80% since there is no need to hire skilled developers or maintain extensive IT teams."
            }
          />
          <ServiceCard
            img_src={web_testing}
            img_alt={"web_testing"}
            card_heading={"User Empowerment"}
            card_para={
              'No-code platforms empower non-technical users (often referred to as "citizen developers") to create applications without needing coding skills.'
            }
          />
          <ServiceCard
            img_src={mobile_testing}
            img_alt={"mobile_testing"}
            card_heading={"Flexibility and Iteration"}
            card_para={
              "Making changes or updates to applications is straightforward with no-code platforms, allowing users to adapt their apps quickly based on feedback or changing requirements."
            }
          />
          <ServiceCard
            img_src={saas_testing}
            img_alt={"saas_testing"}
            card_heading={"Integration Capabilities"}
            card_para={
              "Many no-code platforms offer built-in integrations with various third-party services and APIs, enhancing the functionality of applications without extensive coding."
            }
          />
          <ServiceCard
            card_heading={
              "Let connect, book a free consultancy session with our experts"
            }
            card_button={"Free Consultancy"}
          />
        </div>
        <div className="behindimagediv">
          <img
            className="behindImageSmall"
            src={behindImageSmall}
            alt="yellow-triangle"
          />
          <img
            className="behindImageLarg"
            src={behindImageSmall}
            alt="yellow-triangle"
          />
        </div>
      </div>
      <DevelopmentProcess title="No-Code App Development Services Process">
        <ServiceStepsProcess
          process_heading="Inception & Elicitation"
          process_para="Talk to the customer Listen to what the customer has to say Brainstorm and prepare project backlog"
          process_step_number="1"
          process_indentify="odd"
        />
        <ServiceStepsProcess
          process_heading="UI/UX Design"
          process_para="Create user-friendly interfaces and engaging experiences. This includes wireframing, prototyping, and establishing a cohesive visual style that enhances usability."
          process_step_number="2"
        />
        <ServiceStepsProcess
          process_heading="Plan sprint"
          process_para="The team reviews the product backlog and selects items they believe can be completed within the sprint timeframe."
          process_step_number="3"
          process_indentify="odd"
          iconShow="dark"
        />
        <ServiceStepsProcess
          process_heading="Execute Sprint"
          process_para="The team focuses on completing the items in the sprint backlog, which consists of tasks selected from the product backlog based on priority and team capacity."
          process_step_number="4"
          iconShow="dark"
        />
        <ServiceStepsProcess
          process_heading="Review"
          process_para="During this step, the development team showcases the work completed, demonstrating new features and functionalities to the customer."
          process_step_number="5"
          process_indentify="odd"
        />
        <ServiceStepsProcess
          process_heading="Deployment & Support"
          process_para="Once testing is complete, the app is released to users through Web hosting platforms."
          process_step_number="6"
        />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const nocodeSolution = graphql`
  query nocodeSolution {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
